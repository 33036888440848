import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store/index'
import vuetify from '@/plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import VueCurrencyInput from 'vue-currency-input'
import snackbar from '@/plugins/snackbar'
import messages from '@/plugins/messages'
import handlerApiError from '@/plugins/handler-api-error'
import '@/plugins/vee-validate'
import '@/utils/components'
import '@/utils/filters'

Vue.use(VueTheMask)
Vue.use(VueCurrencyInput)
Vue.use(snackbar)
Vue.use(messages)
Vue.use(handlerApiError)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDxXLttnXD1auRRCb5uvQhPvOhaO45GqHY',
    libraries: 'places',
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
