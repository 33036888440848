export default [
  {
    path: 'obituary/',
    component: () => import('@/views/admin/pages/obituary/ObituaryList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Obituário',
          disabled: true,
        },
      ],
    },
  },
]
