export default [
  {
    path: 'products/',
    component: () => import('@/views/admin/pages/products/ProductsList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Produtos',
          disabled: true,
        },
      ],
    },
  },
]
