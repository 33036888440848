import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import layout from '@/store/modules/layout'
import user from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    user,
  },
  actions: {
    logout({ commit }) {
      commit('user/SET_TOKEN', null)
      commit('user/SET_USER', {})
    },
  },
  plugins: [
    createPersistedState({
      key: 'vuex',
      reducer(val) {
        return {
          user: val.user,
        }
      },
    }),
  ],
})
