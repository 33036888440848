import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'

import users from './modules/admin/users'
import products from './modules/admin/products'
import obituary from './modules/admin/obituary'

import site from './modules/site/pages'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/site/Index'),
    children: [...site],
  },
  {
    name: 'login',
    path: '/admin/login',
    component: () => import('@/views/admin/pages/Login'),
    meta: { redirectIfAuth: true },
  },
  {
    path: '/admin',
    component: () => import('@/views/admin/Index'),
    children: [
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/admin/pages/Dashboard'),
        meta: { auth: true },
      },
      ...users,
      ...products,
      ...obituary,
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  var token = store.state.user.token

  if (to.matched.some(record => record.meta.auth) && !token) {
    return next({ path: '/admin/login' })
  }

  if (to.matched.some(record => record.meta.redirectIfAuth) && token) {
    return next({ path: '/admin' })
  }

  return next()
})

export default router
