export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  mutations: {
    SET_TOKEN (state, payload) {
      state.token = payload
    },
    SET_USER (state, payload) {
      state.user = payload
    },
  },
}
