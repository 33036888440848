export default [
  {
    path: 'users/',
    component: () => import('@/views/admin/pages/users/UsersList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Usuários',
          disabled: true,
        },
      ],
    },
  },
]
