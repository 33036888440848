export default [
  {
    path: '',
    component: () => import('@/views/site/pages/Home'),
  },
  {
    path: 'quem-somos',
    component: () => import('@/views/site/pages/About'),
  },
  {
    path: 'servicos',
    component: () => import('@/views/site/pages/Services'),
  },
  {
    path: 'produtos',
    component: () => import('@/views/site/pages/Products'),
  },
  {
    path: 'obituario',
    component: () => import('@/views/site/pages/Obituary'),
  },
]
